import React, { useState, useEffect } from "react";
import BtnLoader from "../../components/btn-loader/BtnLoader";
// import Header from '../header/Header'
import Sidebar from "../sidebar/Sidebar";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ConnectedFocusError } from "focus-formik-error";
import axios from "axios";
import swal from "sweetalert";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopHeader from "../../components/header-top/TopHeader";
import branch from "../../assets/json/branch.json";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";

const fields = {
  companyName: "",
  mobile: "",
  email: "",
  location: "",
  candidates: "",
  // experienceLevel: "",
   qualification: "",
  role: "",
  jobDescription: "",
  requiredSkill: "",
  employmentType: "",
  applyingDate: "",
  salary: "",
  emailed_at: "",
  contact_person: "",
  degree: "",
  branch: "",
  // termsAndCondition: false
};

const DisplayingErrorMessagesSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .matches(/[^\s*].*[^\s*]/g, "This field can not contain blankspaces.")
    .required("Company Name is required."),
  contact_person: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .matches(/[^\s*].*[^\s*]/g, "This field can not contain blankspaces.")
    .required("Contact Person Name is required."),
  email: Yup.string()
    .email("Email is invalid.")
    .required("Email Address is required.")
    .email("Enter a valid Email Address."),
  mobile: Yup.string()
    .min(10, "Please put 10 digit mobile number.")
    .max(10, "Please put 10 digit mobile number.")
    .matches(/^[6-9]\d{9}$/, {
      message: "Please enter valid number.",
      excludeEmptyString: false,
    }),
  // .required("Phone Number is required."),
  location: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "This field can not contain blankspaces.")
    .required("Location is required."),
  candidates: Yup.string()
    .matches(/^[0-9]+$/, {
      message: "Please enter valid quantity.",
      excludeEmptyString: false,
    })
    .required("Candidates are required."),
  // experienceLevel: Yup.string().required("Experiance Level is required."),
  salary: Yup.string()
    .matches(/^[0-9]+$/, {
      message: "Please enter valid salary.",
      excludeEmptyString: false,
    })
    .required("Salary is required."),
  // qualification: Yup.string()
  //   .matches(/[^\s*].*[^\s*]/g, 'This field can not contain blankspaces.')
  //   .required("Qualification is required."),
  role: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "This field can not contain blankspaces.")
    .required("Job Role is required."),
  jobDescription: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "This field can not contain blankspaces.")
    .required("Job Description is required."),
  requiredSkill: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "This field can not contain blankspaces.")
    .required("Skill is required."),
  employmentType: Yup.string().required("Employment Type is required."),
  emailed_at: Yup.string()
    .email("Email is invalid.")
    .required("Email Address is required.")
    .email("Enter a valid Email Address."),
  applyingDate: Yup.string()
    .matches(/^\d{2}\/\d{2}\/\d{4}$/, {
      message: "Please enter valid Date with formate DD/MM/YYYY.",
      excludeEmptyString: false,
    })
    .required("Applying Date is required."),
  // termsAndCondition: Yup.bool().oneOf([true], 'Required.')
  degree: Yup.string().required("degree is required."),
  branch: Yup.string().required("branch is required."),
});

const JobPost = () => {
  const options = [
    { label: "Fresher", value: "fresher" },
    { label: "Exp - 1", value: "exp-1" },
    { label: "Exp - 2", value: "exp-2" },
    { label: "Exp - 3", value: "exp-3" },
    { label: "Exp - 4", value: "exp-4" },
    { label: "Exp - 5+", value: "exp-5+" },
  ];

  const [selected, setSelected] = useState([]);
  // console.log("selected", selected);
  const [isLoading, setIsLoading] = useState(false);
  const [degree, setDegree] = useState([]);
  const [branchList, setBranch] = useState([]);

  useEffect(() => {
    const data = ["BE", "BPharm", "MTech", "MSC", "MBA", "MPharm", "PHD"];
    setDegree(data);
  }, []);

  const handleSubmit = async (data, resetForm) => {
    setIsLoading(true);
    // console.log("formdata", data);
    data.qualification = selected
    // console.log("formdata", data.qualification);
    setIsLoading(false);
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/wp-json/jobpost/add`,
        { headers, data }
      );
      // console.log(res?.data);
      if(res){
        setSelected([])
      }
      swal({
        icon: "success",
        title: res.data.message,
      });
      resetForm();
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
      });
    }
  };
  const handleSelect = (e) => {
    setSelected(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  return (
    <>
      <TopHeader />
      <Header />
      <main className="col-md-12 ms-sm-auto col-lg-12 col-xl-12  px-md-4">
        <div className="container py-5 h-100 donation-page">
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-12 col-lg-10 col-xl-10">
              <div className="card shadow-2-strong card-registration">
                <div className="card-body p-4 p-md-5">
                  <h3 className="mb-4 pb-2 pb-md-0 mb-md-5 text-center">
                    JOB DETAILS
                  </h3>
                  <Formik
                    initialValues={fields}
                    validationSchema={DisplayingErrorMessagesSchema}
                    onSubmit={(values, { resetForm }) =>
                      handleSubmit(values, resetForm)
                    }
                  >
                    {({ errors, touched, setFieldValue }) => (
                      <Form>
                        <ConnectedFocusError />
                        <div className="row ">
                          <div className="col-md-6">
                            <div className="form-outline pb-2">
                              <Field
                                name="companyName"
                                className="form-control "
                                placeholder="Company Name"
                              />
                              {errors.companyName && touched.companyName && (
                                <div className="error">
                                  {errors.companyName}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-outline pb-2">
                              <Field
                                name="location"
                                className="form-control "
                                placeholder="Location"
                                style={{ borderRadius: "0px" }}
                              />
                              {errors.location && touched.location && (
                                <div className="error">{errors.location}</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-outline">
                              <Field
                                name="email"
                                className="form-control"
                                placeholder="email"
                              />
                              {errors.email && touched.email && (
                                <div className="error">{errors.email}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-outline">
                              <Field
                                name="mobile"
                                className="form-control "
                                placeholder="phone number"
                              />
                              {errors.mobile && touched.mobile && (
                                <div className="error">{errors.mobile}</div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-md-6">
                            <div className="form-outline">
                              <Field
                                as="select"
                                name="degree"
                                className="form-control form-select border-light"
                                onChange={(e) => {
                                  const selectedDegree = e.target.value;
                                  setFieldValue("degree", selectedDegree);

                                  const filterdBranch = branch.filter(
                                    (branch) => {
                                      return branch.degree === selectedDegree;
                                    }
                                  );

                                  setBranch(filterdBranch);
                                }}
                              >
                                <option key="degree" value="">
                                  SELECT DEGREE
                                </option>
                                {degree &&
                                  degree.map((option, index) => {
                                    return (
                                      <>
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      </>
                                    );
                                  })}
                              </Field>
                              {errors.degree && touched.degree && (
                                <div className="error">{errors.degree}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-outline">
                              <Field
                                as="select"
                                name="branch"
                                className="form-control form-select border-light"
                              >
                                <option key="branch" value="">
                                  SELECT BRANCH
                                </option>
                                {branchList.length > 0 &&
                                  branchList.map((option, index) => {
                                    return (
                                      <>
                                        <option key={index} value={option.name}>
                                          {option.name}
                                        </option>
                                      </>
                                    );
                                  })}
                              </Field>
                            </div>
                            {errors.branch && touched.branch && (
                              <div className="error">{errors.branch}</div>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-outline  mt-2">
                              <Field
                                name="contact_person"
                                className="form-control "
                                placeholder="Contact Person Name"
                                style={{ borderRadius: "0px" }}
                              />
                              {errors.contact_person &&
                                touched.contact_person && (
                                  <div className="error">
                                    {errors.contact_person}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-outline  mt-2">
                              <Field
                                name="candidates"
                                className="form-control "
                                placeholder="Number of positions"
                                style={{ borderRadius: "0px" }}
                              />
                              {errors.candidates && touched.candidates && (
                                <div className="error">{errors.candidates}</div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          {/* <label className="mb-2 block">Select Here</label> */}
                          <div className="row">
                            <div className="col-md-12">
                              <div className=" form-outline  mt-2">
                                {/* <label className="mb-2 block">
                                  SELECT QUALIFICATION
                                </label> */}
                                {/* <MultiSelect
                          options={options}
                         value={selected}
                         onChange={setSelected}
                         labelledBy="Select"
                         hasSelectAll ={false}
                         name="qualification"
                         required
                         
                    /> */}

                                <Select
                                  className="dropdown"
                                  placeholder="FRESHER/EXPERIENCED"
                                  value={options.filter((obj) =>
                                    selected.includes(obj.value)
                                  )} // set selected values
                                  options={options} // set list of the data
                                  onChange={handleSelect} // assign onChange function
                                  isMulti
                                  isClearable
                                  name="qualification"
                                  required
                                />
                              </div>
                              {errors.qualification && touched.qualification && (
                                <div className="error">{errors.qualification}</div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* <div className="row">
                          <div className="col-md-12">
                            <div className="form-outline  mt-2">
                              <Field
                                name="qualification"
                                className="form-control "
                                placeholder="Qualification"
                                style={{ borderRadius: "0px" }}
                              />
                              {errors.qualification && touched.qualification && (
                                <div className="error">{errors.qualification}</div>
                              )}
                            </div>
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-outline  mt-2">
                              <Field
                                name="role"
                                className="form-control "
                                placeholder="Job Role"
                                style={{ borderRadius: "0px" }}
                              />
                              {errors.role && touched.role && (
                                <div className="error">{errors.role}</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-outline  mt-2">
                              <Field
                                as="textarea"
                                name="jobDescription"
                                className="form-control "
                                placeholder="JOB DESCRIPTION"
                                style={{ borderRadius: "0px" }}
                              />
                              {errors.jobDescription &&
                                touched.jobDescription && (
                                  <div className="error">
                                    {errors.jobDescription}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-outline  mt-2">
                              <Field
                                name="requiredSkill"
                                className="form-control "
                                placeholder="Required Skills"
                                style={{ borderRadius: "0px" }}
                              />
                              {errors.requiredSkill &&
                                touched.requiredSkill && (
                                  <div className="error">
                                    {errors.requiredSkill}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="cs-border form-outline  mt-2">
                              <Field
                                as="select"
                                name="employmentType"
                                className=" form-select "
                              >
                                <option key="0" value="">
                                  SELECT EMPLOYMENT TYPE{" "}
                                </option>
                                <option key="1" value="Full_Time">
                                  Full Time
                                </option>
                                <option key="2" value="InternShip">
                                  INTERNSHIP
                                </option>
                              </Field>
                            </div>
                            {errors.employmentType &&
                              touched.employmentType && (
                                <div className="error">
                                  {errors.employmentType}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-outline  mt-2">
                              <Field
                                name="salary"
                                className="form-control "
                                placeholder="Enter Salary"
                                style={{ borderRadius: "0px" }}
                              />
                              {errors.salary && touched.salary && (
                                <div className="error">{errors.salary}</div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-outline  mt-2">
                              <Field
                                name="emailed_at"
                                className="form-control"
                                placeholder="Resume to be emailed at"
                              />
                              {errors.emailed_at && touched.emailed_at && (
                                <div className="error">{errors.emailed_at}</div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-outline  mt-2">
                              <Field
                                name="applyingDate"
                                className="form-control "
                                placeholder="Apply Before"
                                style={{ borderRadius: "0px" }}
                              />
                              {errors.applyingDate && touched.applyingDate && (
                                <div className="error">
                                  {errors.applyingDate}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="btn-loader">
                            <button
                              className="alumini-btn"
                              type="submit"
                              value="register"
                            >
                              {" "}
                              <span className="load">
                                SUBMIT{isLoading && <BtnLoader />}
                              </span>
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default JobPost;
