const initialState = {
    data : {},
    token : '',
    user_profile : ''
  }
  
  export default function userReducer(state = initialState, action) {

    console.log("action", action);
    switch (action.type) {
        case 'SET_USER': {
            return {
            token : action.payload.token,
            user_profile : action.payload.user_profile,
            data: action.payload
            }
        }

        case 'SET_USER_PROFILE': {
            return {
            ...state,
            user_profile : action.payload.profile_image,
            }
        }
        case 'LOGOUT':

            console.log("logout");
            return {
                data : {},
                token : '',
                user_profile : ''
            }
        default:
            return state;
    }
  }