import React, { useState, useCallback, useEffect } from "react";
import Footer from "../footer/Footer";
import TopHeader from "../header-top/TopHeader";
import Header from "../header/Header";
import Loader from "../../assets/F4IA.gif";
import "./style.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import Photo1 from "../../assets/Alumni-Day-2015/Event-1-min.JPG";
import Photo2 from "../../assets/Alumni-Day-2015/Event-2-min.JPG";
import Photo3 from "../../assets/Alumni-Day-2015/Event-3-min.JPG";
import Photo4 from "../../assets/Alumni-Day-2015/Event-4-min.JPG";
import Photo5 from "../../assets/Alumni-Day-2015/Event-5-min.JPG";
import Photo6 from "../../assets/Alumni-Day-2015/Event-6-min.JPG";
import Photo7 from "../../assets/Alumni-Day-2015/Event-7-min.JPG";
import Photo8 from "../../assets/Alumni-Day-2015/Event-8-min.JPG";
import Photo9 from "../../assets/Alumni-Day-2015/Event-9-min.JPG";
import Photo10 from "../../assets/Alumni-Day-2015/Event-10-min.JPG";
import Photo11 from "../../assets/Alumni-Day-2015/Event-11-min.JPG";
import Photo12 from "../../assets/Alumni-Day-2015/Event-12-min.JPG";
import Photo13 from "../../assets/Alumni-Day-2015/Event-13-min.JPG";
import Photo14 from "../../assets/Alumni-Day-2015/Event-14-min.JPG";
import Photo15 from "../../assets/Alumni-Day-2015/Event-15-min.JPG";
import { Link } from "react-router-dom";

function GridGallery2015() {
  const GalleryPhoto = [
    {
      image: Photo1,
    },
    {
      image: Photo2,
    },
    {
      image: Photo3,
    },
    {
      image: Photo4,
    },
    {
      image: Photo5,
    },
    {
      image: Photo6,
    },
    {
      image: Photo7,
    },
    {
      image: Photo8,
    },
    {
      image: Photo9,
    },
    {
      image: Photo10,
    },
    {
      image: Photo11,
    },
    {
      image: Photo12,
    },
    {
      image: Photo13,
    },
    {
      image: Photo14,
    },
    {
      image: Photo15,
    },
  ];
  const [urls, setUrls] = useState([]);
  const [isPageLoading, setISPageLoading] = useState(false);
  useEffect(() => {
    setISPageLoading(true);

    const urls = GalleryPhoto.map((e) => {
      return {
        src: e?.image,
        width: 200,
        height: 200,
      };
    });
    setUrls(urls);
    setISPageLoading(false);
  }, []);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <TopHeader />
      <Header />
      <div className="gallery-banner">
        <h2 className="feature-title">GALLERY</h2>
      </div>
      <div>
        {isPageLoading ? (
          <img className="w-loader" src={Loader} alt="loading..." />
        ) : (
          <div>
            <section className="galley">
              <div className="container">
                <div className="g-title">
                  Our Memories <small>- 2015</small>
                </div>
                <div className="back-btn">
                  <Link to="/news-event">
                    <i class="fa fa-long-arrow-left"> </i>
                  </Link>
                  <span> Back</span>
                </div>

                <Gallery photos={urls} onClick={openLightbox} />
              </div>
            </section>
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={urls.map((x) => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title,
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        )}{" "}
      </div>
      <Footer />
    </>
  );
}
export default GridGallery2015;
