import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux'

const PrivateRoute = ({ children}) => {

    const {token} = useSelector((state) => state.user.data);
    console.log("token", token);
    const isAuthenticated = (token != null ) ? true : false;
    console.log("isAuthenticated", isAuthenticated) 
    
    if (isAuthenticated ) {
      return children
    }
      
    return <Navigate to="/login" />
  }

  export default PrivateRoute;