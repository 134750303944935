import React from "react";
import Footer from "../components/footer/Footer";
import TopHeader from "../components/header-top/TopHeader";
import Header from "../components/header/Header";
import Kumar from "../assets/kumar.png";

const FoundationDayEventPage = () => {
  return (
    <>
      <TopHeader />
      <Header />
      <section>
        <div className="eventposter">
          <img src={Kumar} className="img-fluid" alt="banner" />
        </div>

        <div className="details-list">
          <div className="grant_event">
            <h2 className="title">
              70<sup>TH</sup> FOUNDATION DAY
            </h2>
          </div>
          <h2 className="sub-titile">Events Detail</h2>
          <p className="title">
            <i class="fa fa-calendar" aria-hidden="true"></i>23-DEC-2022 -
            MORNING SESSION
          </p>
          <p className="item">
            Round table/ Panel discussion with prominent alumni followed by
            lunch.{" "}
          </p>
         
          <p className="title">
            <i class="fa fa-calendar" aria-hidden="true"></i>23-DEC-2022 -
            EVENING SESSION
          </p>
          <p className="item">
            An evening with Dr Kumar Vishwas’s program at 6:30 PM.{" "}
          </p>
          <p className="item">Dinner will be served after the event.</p>
          <p className="title">
            <i class="fa fa-calendar" aria-hidden="true"></i>24-DEC-2022 -
            MORNING SESSION
          </p>
          <p className="item">
            Marathon at 7.30 AM followed by Indori Breakfast (Poha, Jalebi &
            Johny Hotdog).
          </p>
          <p className="item">
            Fun filled activities and games followed by lunch Post Lunch Alumni
            can do campus visit (labs, classroom, canteen, hostel, workshop
            ,etc) selfie points & selfie competition.
          </p>
          <p className="title">
            <i class="fa fa-calendar" aria-hidden="true"></i>24-DEC-2022 -
            EVENING SESSION
          </p>
          <p className="item">
            6:30 PM Cultural program by alumni & students and felicitation of
            distinguished alumni followed by dinner.
          </p>
          <div className="get-pass">
            <a
              href="https://event.sgsitsalumniassociation.com/"
              target="/blank"
            >
              Regsitration for Event
            </a>
          </div>
          <h4 className="vanue-gsits">
            Venue : Shri Govindram Seksaria Institute of Technology and Science
          </h4>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FoundationDayEventPage;
