import React from "react";
import "./style.css";

const BtnLoader = () => {
  return (
    <>
      <div className="lds-dual-ring"></div>
    </>
  );
};

export default BtnLoader;
