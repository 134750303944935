import React, { useState, useCallback, useEffect } from "react";
import Footer from "../footer/Footer";
import TopHeader from "../header-top/TopHeader";
import Header from "../header/Header";
import { Link } from "react-router-dom";
import "./style.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Photo1 from "../../assets/Alumni-Day-2018/Event-1-min.jpg";
import Photo2 from "../../assets/Alumni-Day-2018/Event-2-min.jpg";
import Photo3 from "../../assets/Alumni-Day-2018/Event-3-min.jpg";
import Photo4 from "../../assets/Alumni-Day-2018/Event-4-min.jpg";
import Photo5 from "../../assets/Alumni-Day-2018/Event-5-min.jpg";
import Photo6 from "../../assets/Alumni-Day-2018/Event-6-min.jpg";
import Photo7 from "../../assets/Alumni-Day-2018/Event-7-min.jpg";
import Photo8 from "../../assets/Alumni-Day-2018/Event-8-min.jpg";
import Photo9 from "../../assets/Alumni-Day-2018/Event-9-min.jpg";
import Photo10 from "../../assets/Alumni-Day-2018/Event-10-min.jpg";
import Photo11 from "../../assets/Alumni-Day-2018/Event-11-min.jpg";
import Photo12 from "../../assets/Alumni-Day-2018/Event-12-min.jpg";
import Photo13 from "../../assets/Alumni-Day-2018/Event-13-min.jpg";
import Photo14 from "../../assets/Alumni-Day-2018/Event-14-min.jpg";
import Photo15 from "../../assets/Alumni-Day-2018/Event-15-min.jpg";
import Photo16 from "../../assets/Alumni-Day-2018/Event-16-min.jpg";

function GridGallery2018() {
  const GalleryPhoto = [
    {
      image: Photo1,
    },
    {
      image: Photo2,
    },
    {
      image: Photo3,
    },
    {
      image: Photo4,
    },
    {
      image: Photo5,
    },
    {
      image: Photo6,
    },
    {
      image: Photo7,
    },
    {
      image: Photo8,
    },
    {
      image: Photo9,
    },
    {
      image: Photo10,
    },
    {
      image: Photo11,
    },
    {
      image: Photo12,
    },
    {
      image: Photo13,
    },
    {
      image: Photo14,
    },
    {
      image: Photo15,
    },
    {
      image: Photo16,
    },
  ];
  const [urls, setUrls] = useState([]);
  useEffect(() => {
    const urls = GalleryPhoto.map((e) => {
      return {
        src: e?.image,
        width: 200,
        height: 200,
      };
    });
    setUrls(urls);
  }, []);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  console.log(GalleryPhoto);
  return (
    <>
      <TopHeader />
      <Header />
      <div className="gallery-banner">
        <h2 className="feature-title">GALLERY</h2>
      </div>
      <section className="galley">
        <div className="container">
          <div className="g-title">
            Our Memories <small>- 2018</small>
          </div>
          <div className="back-btn">
            <Link to="/news-event">
              <i class="fa fa-long-arrow-left"> </i>
            </Link>
            <span> Back</span>
          </div>

          <Gallery photos={urls} onClick={openLightbox} />
        </div>
      </section>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={urls.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <Footer />
    </>
  );
}

export default GridGallery2018;
