import React from "react";
import { Link, NavLink } from "react-router-dom";
import pdf from "../../assets/doc/alumni.pdf"

const Sidebar = () => {
  const user_nicename = localStorage.getItem("user_nicename");
  return (
    <>
      <nav
        id="sidebarMenu"
        className="col-md-3 col-lg-3 col-xl-2 d-md-block sidebar collapse"
      >
        <div className="Dashboard">Dashboard</div>

        <div className="position-sticky pt-3">
          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLink to="/dashboard" className="nav-link" aria-current="page">
                Profile
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/reset-password" className="nav-link">
                Change Password
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/searchTeamMates" className="nav-link">
                Search Team Mates
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/news-event" className="nav-link">
                News & Event
              </NavLink>
            </li>
            <li className="nav-item">
              <a
                href={pdf}
                target="_blank"
                className="nav-link"
              >
                Constitution
              </a>
            </li>
            <li className="nav-item">
              <NavLink to="/job-details" className="nav-link">
                Job Posting
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/follow" className="nav-link">
                Join Social Media
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
