import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Logo from "../../assets/logo.png";
import "./style.css";

const Footer = (props) => {
  const { token } = props?.user?.data;

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <p className="description">
                Shri Govindram Seksaria Institute of Technology and Science,
                Indore was established in 1952. SGSITS is recognized as a
                leading institute in the state of Madhya Pradesh.
              </p>
            </div>
            <div className="col-lg-2">
              <h6 className="title">USEFUL LINKS</h6>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link to="/" className="nav-link p-0">
                    home
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/Alumini" className="nav-link p-0">
                    alumni
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/news-event" className="nav-link p-0">
                    news & event
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/committe" className="nav-link p-0">
                    committe
                  </Link>
                </li>

                <li className="nav-item mb-2">
                  <a href="https://sgsitsalumniassociation.com/audit_report/audit-report-22-23.pdf" target="_blank" className="nav-link p-0">
                    Audit Report 2022-2023
                  </a>
                </li>

                <li className="nav-item mb-2">
                  <a href="https://sgsitsalumniassociation.com/audit_report/audit-report-21-22.pdf" target="_blank" className="nav-link p-0">
                    Audit Report 2021-2022
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2">
              <h6 className="title about">About</h6>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <Link to="/search" className="nav-link p-0">
                    Search
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/gallery" className="nav-link p-0">
                    gallery
                  </Link>
                </li>

                <li className="nav-item mb-2">
                  <Link to="/job-details" className="nav-link p-0">
                  JOB-POST
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/contact" className="nav-link p-0">
                    contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 ">
              <div className="login">
                {token ? (
                  <Link
                    to="/dashboard"
                    className="btn btn-lg btn-danger text-uppercase"
                  >
                    Login dashboard
                  </Link>
                ) : (
                  <Link
                    to="/login"
                    className="btn btn-lg btn-danger text-uppercase"
                  >
                    Login dashboard
                  </Link>
                )}
              </div>
              <div className="social-icon">
                <ul className="nav">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/groups/sgsitsalumni/?ref=share"
                    >
                      <i className="me-2 fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://instagram.com/sgsits_alumni_association?%20igshid=YmMyMTA2M2Y"
                    >
                      <i
                        className="me-2 fa fa-instagram"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://t.me/joinchat/WvTLT_M69lw2MjJl"
                    >
                      <i className="me-2 fa fa-telegram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/groups/10311867/"
                    >
                      <i className="me-2 fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row"></div>
          <div className="col-md-12 footer-bottom">
            <p className="copyright-contant">
              © 2022 SGSITS Alumni / All rights reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => {
  console.log("login state", state);
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Footer);
