import React from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/logo-al.png";
import "./style.css";

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid bg-mobile">
          <Link to="/" className="navbar-brand mobile-show">
            <img src={Logo} alt="Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto  mb-lg-0 mobile-m">
              {/* <li className="nav-item">
                <NavLink to="/job-details" className="nav-link" aria-current="page">
                JOB/INTERNSHIP POST
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink to="/about" className="nav-link ">
                  ABOUT
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/alumini" className="nav-link">
                  ALUMNI
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/news-event" className="nav-link">
                  NEWS & EVENTS
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/committe" className="nav-link ">
                  COMMITTE
                </NavLink>
              </li>
            </ul>
            <div className="logo-desktop">
              <NavLink to="/" className="navbar-brand mobile-hide">
                <img src={Logo} alt="Logo" />
              </NavLink>
            </div>
            <ul className="me-auto navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/donation" className="nav-link ">
                  DONATION
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/gallery" className="nav-link ">
                  GALLERY
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink to="/about" className="nav-link ">
                  ABOUT
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink to="/job-details" className="nav-link" aria-current="page">
                JOB/INTERNSHIP POST
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/contact" className="nav-link ">
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
